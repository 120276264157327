import { Box } from '@mui/material';
import React from 'react';
import { Material } from './MaterialsContent';
import { t } from 'i18next';

const Technical = ({ materialDetail }: { materialDetail: Material }) => {
  if (!materialDetail) return null;
  return (
    <Box>
      <div className="w-full">
        {materialDetail.designSpecifications && (
          <>
            <h3 className="text-2xl font-semibold mt-9 mb-1">{t('design specifications')}</h3>
            <Box sx={{ borderRadius: '10px' }}>
              {/* <table className="table mt-2 mb-0 material-detail-table"> */}
              <table className="table mt-2 mb-0 material-detail-table table-auto">
                <thead>
                  <tr>
                    <th className="text-lg font-semibold">{t('standard capabilities')}</th>
                    <th className="text-lg font-semibold">{t('description')}</th>
                  </tr>
                </thead>
                <tbody>
                  {materialDetail.designSpecifications?.map((item, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap text-lg">
                        <b>{t(item.title)}</b>
                      </td>
                      <td>{t(item.content)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </>
        )}
      </div>
    </Box>
  );
};

export default Technical;
