import { CaretDownOutlined } from '@ant-design/icons';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Tab,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import banner3d_2 from 'src/assets/images/materials/banner3d_2.png';
import Banner from 'src/components/banner/Banner';
import B2CAction from 'src/pages/components/layout/B2CAction';
import { ColorContents, TColorContent } from './ColorContents';
import ColorFinishes from './ColorFinishes';
import { Material, MaterialContent } from './MaterialsContent';
import Overview from './Overview';
import Technical from './Technical';
import { defaultLanguage } from 'src/locales/i18n';
import { t } from 'i18next';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

const TabBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 16,
  width: '100%',
  marginTop: 16,
  [theme.breakpoints.down('sm')]: {},
}));

const SpecialTabList = styled(TabList)(({ theme }) => ({
  transition: '1s',
  '& button': {
    color: 'var(--color-gray)',
    minWidth: 160,
    fontSize: 16,
  },
  '& button.Mui-selected': {
    color: 'var(--color-white)',
  },
  [theme.breakpoints.down('sm')]: {
    '& button': {
      fontSize: 14,
      minWidth: 50,
    },
  },
}));

const ViewMoreBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 8,
  svg: { color: 'var(--app-color-primary-blue)' },
  position: 'relative',
  '& .dropdown': {
    display: 'none',
    position: 'absolute',
    top: '100%',
    right: '0',
    minWidth: '150px',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    padding: '16px 24px',
    zIndex: 100,
    '& a': {
      color: 'var(--app-color-text-5)',
      textDecoration: 'none',
      '&:hover': {
        color: 'var(--app-color-primary-blue)',
      },
    },
    '& ul': {
      listStyle: 'disc',
      a: { fontSize: 16 },
    },
  },
  '&:hover': {
    cursor: 'pointer',
    '& .dropdown': {
      display: 'block',
    },
  },
  [theme.breakpoints.down('sm')]: {},
}));

export const SliderCustom = styled(Slider)(({ theme }) => ({
  marginTop: '16px',
  marginBottom: '24px',
  '& .slick-slide': {
    padding: '8px',
  },
  '& .slick-arrow.slick-next, .slick-arrow.slick-prev': {
    zIndex: 3,
    display: 'none',
  },
  '& .get-instant-quote': {
    background: '#F2F2F2',
    fontWeight: '600',
    width: '100%',
    marginTop: '16px',
    borderRadius: '8px',
    padding: '12px 0',
    border: 'none',
    color: 'var(--app-color-text-5)',
    '&:hover': {
      backgroundColor: 'var(--app-color-primary-blue)',
      color: '#FFF',
    },
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '16px',
    marginBottom: '24px',
    '& .slick-slide': {
      padding: '4px',
    },
  },
}));

const availableSliderSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  autoplay: true,
  arrows: false,
  centerMode: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const MaterialDetail = () => {
  const navigate = useNavigate();
  const { materialCode, materialColor } = useParams();
  const [content, setContent] = React.useState<Material>({} as Material);
  // const [contentDesign, setContentDesign] = React.useState<DesignGuideContent>(
  //   {} as DesignGuideContent
  // );
  const [contentFinishes, setContentFinishes] = React.useState<TColorContent>({} as TColorContent);
  const [tabIndex, setTabIndex] = useState('overview');
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const { getLang } = useLocalStorage();

  useEffect(() => {
    if (materialColor) {
      setTabIndex('color');
    }
  }, [materialColor]);

  const tabsListConfig = useMemo(() => {
    return [
      {
        label: 'material overview',
        value: 'overview',
        component: <Overview materialDetail={content} />,
      },
      {
        label: 'color & finishes',
        value: 'color',
        component: <ColorFinishes materialDetail={contentFinishes} />,
      },
      // {
      //   label: 'design guide',
      //   value: 'guide',
      //   component: <DesignGuide materialDetail={contentDesign} />,
      // },
      {
        label: 'technical specifications',
        value: 'technical',
        component: <Technical materialDetail={content} />,
      },
    ];
  }, [content, contentFinishes]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setLoading(true);
    setTabIndex(newValue);
    setLoading(false);
  };

  useEffect(() => {
    if (materialCode) {
      const material = MaterialContent.find((item) => item.code === materialCode);
      if (material) {
        setContent(material);
        // const materialDesign = DesignGuideContents.find(
        //   (item) => item.code === material.materialType
        // );
        // if (materialDesign) {
        //   setContentDesign(materialDesign);
        // }
        const materialFinishes = ColorContents.find((item) => item.code === material.code);
        if (materialFinishes) {
          setContentFinishes(materialFinishes);
        }
      }
    }
  }, [materialCode]);

  return (
    content && (
      <>
        <Banner
          style={{
            height: '466px',
            background: `url(${banner3d_2})`,
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '48px 16px',
              }}
            >
              <Banner.Text
                classBanner="max-[1024px]:p-4 p-0"
                titleBlack={t(content.name)}
                // titleBlack={t('3D printing in', { value: content.name })}
                descriptionSmall={content.subtitle && t(content.subtitle)}
                descriptionSmallProps={{
                  style: {
                    width: '100%',
                    margin: '0 auto 30px',
                    color: '#6B6B6B',
                  },
                }}
                action={
                  <Button
                    color="primary"
                    size="lg"
                    className="bg-[#0076E2] color-[#ffffff] border-none"
                    onClick={() => navigate('/get-instant-quote')}
                  >
                    {t('Order 3D printed parts', { value: content.name })}
                  </Button>
                }
              />
            </Box>
          </Container>
        </Banner>
        <Container maxWidth="lg">
          <TabContext value={tabIndex}>
            <TabBox>
              <SpecialTabList
                indicatorColor="secondary"
                onChange={handleChange}
                variant={isMobile ? 'scrollable' : 'standard'}
                scrollButtons={'auto'}
              >
                {tabsListConfig.map((item, index) => (
                  <Tab
                    key={index}
                    label={t(item.label)}
                    value={item.value}
                    sx={{ textTransform: 'uppercase' }}
                    wrapped
                  />
                ))}
              </SpecialTabList>
              <ViewMoreBox>
                {!isMobile && (
                  <Typography color={'var(--app-color-primary-blue)'} variant="h6">
                    {t('view other materials')}{' '}
                  </Typography>
                )}
                <CaretDownOutlined rev={''} />
                <div className="dropdown">
                  <h5 className="sub-title">{t('Plastics')}</h5>
                  <ul>
                    <li>
                      <Link to="/material/PLA(FDM)">PLA(FDM)</Link>
                    </li>
                    <li>
                      <Link to="/material/ABS(FDM)">ABS(FDM)</Link>
                    </li>
                    <li>
                      <Link to="/material/Gray-Resin">Gray Resin</Link>
                    </li>
                    {/* <li>
                      <Link to="/material/Nylon">Nylon</Link>
                    </li>
                    <li>
                      <Link to="/material/PETG">PETG</Link>
                    </li>
                    <li>
                      <Link to="/material/TPU">TPU</Link>
                    </li>
                    <li>
                      <Link to="/material/ASA">ASA</Link>
                    </li>
                    <li>
                      <Link to="/material/PEI">PEI</Link>
                    </li>
                    <li>
                      <Link to="/material/PC">PC</Link>
                    </li>
                    <li>
                      <Link to="/material/Standard-Resin">Standard Resin</Link>
                    </li>*/}
                    <li>
                      <Link to="/material/High-detail-Resin">High-detail Resin</Link>
                    </li>
                    <li>
                      <Link to="/material/Rubber-like(MJF)">Rubber-like (MJF)</Link>
                    </li>
                    <li>
                      <Link to="/material/Transparent-Resin">Transparent Resin</Link>
                    </li>
                  </ul>

                  <h5 className="sub-title">{t('Full Color')}</h5>
                  <ul>
                    <li>
                      <Link to="/material/Multicolor+">Multicolor+</Link>
                    </li>
                  </ul>
                  <h5 className="sub-title">{t('Metals')}</h5>
                  <ul>
                    <li>
                      <Link to="/material/Aluminum">Aluminum</Link>
                    </li>
                    <li>
                      <Link to="/material/High-Detail-Stainless-Steel">
                        High-Detail Stainless Steel
                      </Link>
                    </li>
                    <li>
                      <Link to="/material/Steel"> Steel</Link>
                    </li>
                    <li>
                      <Link to="/material/Titanium">Titanium</Link>
                    </li>
                  </ul>
                  <h5 className="sub-title">{t('Precious Metals')}</h5>
                  <ul>
                    <li>
                      <Link to="/material/Brass">Brass </Link>
                    </li>
                    <li>
                      <Link to="/material/Bronze"> Bronze </Link>
                    </li>
                    <li>
                      <Link to="/material/Copper">Copper</Link>
                    </li>
                    <li>
                      <Link to="/material/Gold">Gold</Link>
                    </li>
                    <li>
                      <Link to="/material/Silver">Silver</Link>
                    </li>
                  </ul>
                </div>
              </ViewMoreBox>
            </TabBox>
            <TabPanel value="overview" sx={{ padding: '24px 0' }}>
              <Overview materialDetail={content} />
            </TabPanel>
            <TabPanel value="color" sx={{ padding: '24px 0' }}>
              <ColorFinishes materialDetail={contentFinishes} />
            </TabPanel>
            <TabPanel value="technical" sx={{ padding: '24px 0' }}>
              <Technical materialDetail={content} />
            </TabPanel>
          </TabContext>
        </Container>
        <Container maxWidth="xl">
          <h1 className="text-left mt-10">{t('available materials')}</h1>
          <SliderCustom {...availableSliderSettings}>
            {MaterialContent.map((item, index) => (
              <Box
                key={index}
                sx={{ border: '1px solid #312F30', borderRadius: '8px', padding: '20px' }}
              >
                <NavLink
                  to={`/material/${item.code}`}
                  style={{ textDecoration: 'none', textAlign: 'left' }}
                >
                  <h3>{t(item.code)}</h3>
                </NavLink>
                <div
                  className="extra mb-3 line-ellipsis-4"
                  style={{ textAlign: 'left', margin: 'auto' }}
                >
                  {/* {t(`prototyping ${item.code}`)} */}
                  {t(item.define[0])}
                </div>
                <button className="button-option">{t(item.materialType)}</button>
                <Button
                  className="get-instant-quote"
                  onClick={() => navigate('/get-instant-quote')}
                >
                  {t('get instant quote')}
                </Button>
              </Box>
            ))}
          </SliderCustom>
        </Container>
        <B2CAction />
      </>
    )
  );
};

export default React.memo(MaterialDetail);
